// import React from "react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { HiPlusSm } from "react-icons/hi";
import st from "../../style.module.scss";
import { checkUrlType } from "../../helper/imagePathHandler";
import ImageModel from "../common/ImageModel";
import { toast as hotToast } from "react-hot-toast";

function Document() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [image, setImage] = useState({ value: "", status: false });
  const [docImage1, setdocImage1] = useState({ value: "", status: false });
  const [docImage2, setdocImage2] = useState({ value: "", status: false });
  const [isImageModal, setIsImageModal] = useState(false);
  const [imageInPopup, setImageInPopup] = useState("");
  const locationLatLng = JSON.parse(localStorage.getItem("location"));
  // console.log(locationLatLng, "ooooooooooooooooooooooooooooooooooooooooo");
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("doc1", data.doc1[0]);
      formData.append("image", data.image[0]);
      data.doc2[0] && formData.append("doc2", data.doc2[0]);
      formData.append("is_document", true);

      formData.append(
        "lat",
        locationLatLng && typeof locationLatLng?.lat === "number"
          ? locationLatLng?.lat
          : 0.0
      );
      formData.append(
        "long",
        locationLatLng && typeof locationLatLng?.lng === "number"
          ? locationLatLng?.lng
          : 0.0
      );
      formData.append("status", "document uploaded");

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/vendor-register/${location?.state}/`,
        {
          method: "PATCH",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(
          responseData?.msg || "Documents are uploaded successfully!"
        );
        localStorage.removeItem("token");
        selectSubscription();
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };
  const selectSubscription = async () => {
    try {
      // const user = JSON.parse(localStorage.getItem("userData"))?.id;
      const formData = new FormData();
      // formData.append("duration", ); // Assuming data.duration corresponds to duration="1"
      formData.append("user", location?.state); // Assuming data.user corresponds to user="119"
      formData.append("plan", 1); // Assuming data.plan corresponds to plan="1"

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/subscriptions/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (response.status === 201) {
          const toastId = hotToast(
            (t) => (
              <div>
                <h5 style={{ fontWeight: "700", marginBottom: "12px" }}>
                  Welcome to e360 Mart
                </h5>
                <p className="mb-2">
                  {" "}
                  We are so very thrilled that you are joining our community.
                  Please allow up to 24 hours for your account to be reviewed
                  and approved.
                </p>
                <p className="mb-2">
                  If we need anything, we will reach out to you asap!
                </p>
                <p>
                  Thanks{" "}
                  <span style={{ fontWeight: "600", color: "#000" }}>
                    @e360 Mart
                  </span>
                </p>
                <div className="text-center">
                  <button
                    className="btn btn-primary px-4 px-2"
                    style={{ minWidth: "205px" }}
                    onClick={() => hotToast.dismiss(t.id)}
                  >
                    OK
                  </button>
                </div>
              </div>
            ),
            {
              duration: Infinity,
              closeButton: true,
              // Add an onClick handler to the close button
              onCloseButtonClick: () => toast.dismiss(toastId),
            }
          );
          setTimeout(() => {
            navigate("/sign-in/", { replace: true });
          }, 1000);
        }
        // getHelpSupportList(); // You may replace this with the appropriate function to update your UI
      } else {
        const data = await response.json();
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error(error, "Error submitting form:");
    }
  };

  const [isUploading, setIsUploading] = useState({
    doc1: false,
    doc2: false,
    doc3: false,
    doc4: false,
  });
  const [uploadProgress, setUploadProgress] = useState({
    doc1: 0,
    doc2: 0,
    doc3: 0,
    doc4: 0,
  });
  const [fileName, setFileName] = useState({
    doc1: "",
    doc2: "",
    doc3: "",
    doc4: "",
  });

  const handleImageChange = (e, type = "image") => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    if (type === "image") {
      setImage({ value: newImage, status: true });
    }
    if (type === "doc1") {
      setdocImage1({ value: newImage, status: true });
    }
    if (type === "doc2") {
      setdocImage2({ value: newImage, status: true });
    }
  };

  const handleFileChange = (event, doc) => {
    const file = event.target.files[0];
    if (file.type.startsWith("image/")) {
      handleImageChange(event, doc);
    } else {
      setFileName((prevFileNames) => ({ ...prevFileNames, [doc]: file.name }));
      setIsUploading((prevIsUploading) => ({
        ...prevIsUploading,
        [doc]: true,
      }));

      if (doc === "doc1") {
        setdocImage1({ value: "", status: false });
      }

      if (doc === "doc2") {
        setdocImage2({ value: "", status: false });
      }

      // Simulate file upload progress
      const uploadInterval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          const newProgress = prevProgress[doc] + 10;
          if (newProgress >= 100) {
            clearInterval(uploadInterval);
            setIsUploading((prevIsUploading) => ({
              ...prevIsUploading,
              [doc]: false,
            }));
          }
          return { ...prevProgress, [doc]: newProgress };
        });
      }, 500); // Simulate upload progress every 500ms
    }
  };

  function onhide() {
    setIsImageModal(false);
  }

  return (
    <>
      <div className="col-lg-6 d-flex justify-content-center">
        <div className="w-100 p-3 p-md-5 card border-0 shadow-sm">
          <form
            className="row g-1 p-3 p-md-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="col-12 text-center mb-5">
              <h1>Upload Your Document</h1>
            </div>

            <div className="col-12">
              <div className="mb-2">
                <label className="form-label">
                  Shop Image
                  <span className="text-danger">*</span>(Only images are
                  allowed)
                </label>
                <div className="d-flex gap-3">
                  {image?.value && (
                    <div className={`${st.dummyImage}`}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={image?.value}
                        alt={`uploaded `}
                        onClick={() => {
                          setIsImageModal(true);
                          setImageInPopup(image?.value);
                        }}
                      />
                    </div>
                  )}
                  <div className={`${st.dummyImage}`}>
                    <HiPlusSm
                    // onClick={(e) => {
                    //   e.preventDefault(); // Prevent any default actions
                    //   const fileInput = document.getElementById("fileInput");
                    //   if (fileInput) {
                    //     fileInput.click(); // Trigger file input click directly on user interaction
                    //   }
                    // }}
                    // onTouchStart={(e) => {
                    //   e.preventDefault(); // Prevent any default actions
                    //   const fileInput = document.getElementById("fileInput");
                    //   if (fileInput) {
                    //     fileInput.click(); // Ensure it's directly tied to a touch event
                    //   }
                    // }}
                    />
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      // style={{ display: "none" }}
                      {...register("image", {
                        required: true,
                        onChange: (e) => handleImageChange(e),
                      })}
                    />
                  </div>
                  {errors.image && (
                    <span className="text-danger">Shop image is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <label className="form-label">
                  Upload citizen ID (NIDA)
                  <span className="text-danger">*</span>(Only images or PDFs are
                  allowed)
                </label>
                <div className="uploadImage">
                  {checkUrlType(docImage1?.value) === "Image" && (
                    <div className={`${st.dummyImage}`}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={docImage1?.value}
                        alt={`uploaded `}
                        onClick={() => {
                          setIsImageModal(true);
                          setImageInPopup(docImage1?.value);
                        }}
                      />
                    </div>
                  )}

                  <button className="btn">
                    <input
                      type="file"
                      className="form-control form-control-lg"
                      accept="image/*,.pdf"
                      {...register("doc1", {
                        required: true,
                        onChange: (e) => {
                          handleFileChange(e, "doc1");
                        },
                      })}
                    />
                    +
                  </button>
                  {isUploading.doc1 && (
                    <div className="imageName w-100 pt-3">
                      <div className="progress-container">
                        <progress
                          className="progressBar"
                          value={uploadProgress.doc1}
                          max="100"
                        ></progress>
                        <span className="progress-value">
                          {uploadProgress.doc1}%
                        </span>
                      </div>
                    </div>
                  )}
                  {!isUploading.doc1 && fileName.doc1 && (
                    <div className="imageName w-100">
                      <input
                        type="text"
                        className="form-control"
                        value={fileName.doc1}
                        readOnly
                      />
                    </div>
                  )}
                </div>
                {errors.doc1 && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <label className="form-label">
                  Upload business registration certificate (BRELA)(Only images
                  or PDFs are allowed)
                </label>
                <div className="uploadImage">
                  {checkUrlType(docImage2?.value) === "Image" && (
                    <div className={`${st.dummyImage}`}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={docImage2?.value}
                        alt={`uploaded `}
                        onClick={() => {
                          setIsImageModal(true);
                          setImageInPopup(docImage2?.value);
                        }}
                      />
                    </div>
                  )}
                  <button className="btn">
                    <input
                      type="file"
                      className="form-control form-control-lg"
                      accept="image/*,.pdf"
                      {...register("doc2", {
                        required: false,
                        onChange: (e) => {
                          handleFileChange(e, "doc2");
                        },
                      })}
                    />
                    +
                  </button>
                  {isUploading.doc2 && (
                    <div className="imageName w-100 pt-3">
                      <div className="progress-container">
                        <progress
                          className="progressBar"
                          value={uploadProgress.doc2}
                          max="100"
                        ></progress>
                        <span className="progress-value">
                          {uploadProgress.doc2}%
                        </span>
                      </div>
                    </div>
                  )}
                  {!isUploading.doc2 && fileName.doc2 && (
                    <div className="imageName w-100">
                      <input
                        type="text"
                        className="form-control"
                        value={fileName.doc2}
                        readOnly
                      />
                    </div>
                  )}
                </div>
                {/* {errors.doc2 && (
                <span className="text-danger">This field is required</span>
              )} */}
              </div>
            </div>
            {/* <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Document 3</label>
              <div className="uploadImage">
                <button className="btn">
                  <input
                    type="file"
                    className="form-control form-control-lg"
                    {...register("doc3", { required: true, onChange: (e) => { handleFileChange(e,'doc3') } })}

                  />
                  +
                </button>
                {isUploading.doc3 && (
                  <div className="imageName w-100">
                    <div className="progress-container">
                      <progress className="progressBar" value={uploadProgress.doc3} max="100"></progress>
                      <span className="progress-value">{uploadProgress.doc3}%</span>
                    </div>
                  </div>
                )}
                {!isUploading.doc3 && fileName.doc3 && (
                  <div className="imageName w-100">
                    <input
                      type="text"
                      className="form-control"
                      value={fileName.doc3}
                      readOnly
                    />
                  </div>
                )}
              </div>
              {errors.doc3 && (
                <span className="text-danger">
                  This field is required
                </span>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Document 4</label>
              <div className="uploadImage">
                <button className="btn">
                  <input
                    type="file"
                    className="form-control form-control-lg"
                    {...register("doc4", { required: true, onChange: (e) => { handleFileChange(e,'doc4') } })}

                  />
                  +
                </button>
                {isUploading.doc4 && (
                  <div className="imageName w-100">
                    <div className="progress-container">
                      <progress className="progressBar" value={uploadProgress.doc4} max="100"></progress>
                      <span className="progress-value">{uploadProgress.doc4}%</span>
                    </div>
                  </div>
                )}
                {!isUploading.doc4 && fileName.doc4 && (
                  <div className="imageName w-100">
                    <input
                      type="text"
                      className="form-control"
                      value={fileName.doc4}
                      readOnly
                    />
                  </div>
                )}
              </div>
              {errors.doc4 && (
                <span className="text-danger">
                  This field is required
                </span>
              )}
            </div>
          </div> */}

            {/* <div className="col-12">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="tandc"
                id="flexCheckDefault"
                {...register("t_and_c", { required: true })}
              />
              {errors.t_and_c && (
                <span className="text-danger">
                  This field is required
                </span>
              )}
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I accept the{" "}
                <Link
                  to="#!"
                  title="Terms and Conditions"
                  className="text-secondary"
                >
                  Terms and Conditions
                </Link>
              </label>
            </div>
          </div> */}
            <div className="col-12 text-center mt-4">
              <Button
                type="submit"
                className="btn btn-lg btn-block btn-light lift text-uppercase w-100 p-3"
              >
                Submit{" "}
              </Button>
            </div>
          </form>
        </div>
      </div>

      <ImageModel
        isImageModal={isImageModal}
        image={imageInPopup}
        onhide={onhide}
      />
    </>
  );
}
export default Document;
